import {
  filter,
  map,
  orderBy,
  concat,
} from 'lodash';

/**
 * Sort objects by wanted property
 * @param items {Object}
 * @param byProperty {string}
 * @returns {Array.<Object>}
 */
const sortDataByProperty = (items) => {
  const defaultPosition = orderBy(filter(items,
    (item) => item.position === 0 || item.position === '0'), ['concreteSelection']);
  const itemsMapped = map(items, (n) => {
    // eslint-disable-next-line no-param-reassign
    n.position = +(n.position);
    return n;
  });
  const filtered = filter(itemsMapped, (item) => item.position > 0);
  const sortedPosition = orderBy(filtered, ['position']);
  return concat(sortedPosition, defaultPosition);
};

export default sortDataByProperty;
