<template>
  <div class="modal-header"
       id="ticketPreviewHeader">
    <p class="modal-title">{{ ticketPreviewData.actionData.message }}</p>
    <p class="modal-subtitle-item">
      {{ translate('shop_ticketType') }}:<strong>{{ ticketType[ticket.type] }}</strong>
    </p>
    <p class="modal-subtitle-item">
      {{ translate('general_payIn') }}:<strong>{{ ticket.payin.toFixed(2) }}</strong>
    </p>
    <p class="modal-subtitle-item">
      {{ getWinnings.description }}:<strong>{{getWinnings.value}}</strong>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TicketPreviewHeader',
  data() {
    return {
      ticketType: {
        1: 'combo',
        3: 'single',
      },
    };
  },
  computed: {
    ...mapGetters(['ticketPreviewData', 'translate']),
    ticket() {
      return this.ticketPreviewData.ticket;
    },
    getWinnings() {
      let description = '';
      let value = '';

      switch (this.ticket.status.value.toLowerCase()) {
        case 'open':
        case 'in_play':
        case 'cancelled':
        case 'expired':
          description = this.translate('general_maxWinShort');
          value = this.ticket.maxPossibleWin.toFixed(2);
          break;
        case 'lost':
        case 'won':
        case 'closed':
          description = this.translate('general_winnings');
          value = this.ticket.winnings.toFixed(2);
          break;
        case 'payedout':
          description = this.translate('general_payoutAmount');
          value = this.ticket.payout.toFixed(2);
          break;
        // no default
      }
      return {
        description,
        value,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  color: #455a64;
  height: 81px;
  padding-bottom: 16px;

  .modal-title {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .modal-subtitle-item {
    display: inline-flex;
    font-size: 14px;
    text-transform: capitalize;

    strong {
      margin-left: 4px;
    }

    &:last-child::after {
      content: none;
    }
  }

  .modal-subtitle-item::after {
    content: '|';
    margin: 0 5px;
  }
}
</style>
