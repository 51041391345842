<template>
  <div class="loader-box">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss" scoped>
.loader-box {
  height: 100%;
  .loader {
    animation: ls-spinner 1s infinite linear;
    border-top: 2px solid rgba(255,255,255,0.2);
    border-right: 2px solid rgba(255,255,255,0.2);
    border-bottom: 2px solid rgba(255,255,255,0.2);
    border-left: 2px solid #ffffff;
    border-radius: 50%;
    height: 50px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    z-index: 9999;
  }
  @keyframes ls-spinner {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
}
</style>
