import {
  orderBy,
  pickBy,
  filter,
  take,
  find,
} from 'lodash';

export default {
  appIsReady: (state) => state.appIsReady,
  loaderStatus: (state) => state.loaderStatus,
  config: (state) => state.config,
  rawConfig: (state) => state.rawConfig,
  language: (state) => state.config.locale,
  tenantId: (state) => state.config.tenantId,
  timezone: () => Intl.DateTimeFormat().resolvedOptions().timeZone,
  categories: (state) => pickBy(state.categories, (value) => value.active),
  tournaments: (state, getters) => pickBy(state.tournaments, (value) => value.active
      && (Object.prototype.hasOwnProperty.call(getters.categories, value.categoryId)
      && getters.categories[value.categoryId].active)),
  translate: (state) => (key) => state.translations[key] || key,
  events: (state, getters) => filter(orderBy(state.events, 'eventStartTimeUTC'),
    (event) => {
      const tempDate = new Date().getTime();
      return event.bettingStatus
        && ((event.eventStartTimeUTC - 60000) > tempDate)
        && (event.tournament.id in getters.tournaments
          && getters.tournaments[event.tournament.id]?.active)
        && (event.category.id in getters.categories
          && getters.categories[event.category.id]?.active);
    }),
  tempTime: (state) => state.tempTime,
  errorPageMessage: (state) => state.errorPageMessage,
  modalBoxComponent: (state) => state.modalBoxComponent,
  ticketPreviewData: (state) => state.ticketPreviewData,
  ticketPreviewBets: (state) => state.ticketPreviewData.ticket.bets,
  selectedEvent: (state) => state.selectedEvent,
  selectEventError: (state) => state.selectEventError,
  selectedNumbers: (state) => state.selectedNumbers,
  bonusBalls: (state) => state.bonusBalls,
  activePrintType: (state) => state.activePrintType,
  randomActive: (state) => state.randomActive,
  appShown: (state) => state.appShown,
  betslipBlockers: (state) => state.betslipBlockers,
  globalStatus: (state) => state.globalStatus,
  fullOfferActive: (state) => state.fullOfferActive,
  searchedFullOfferValue: (state) => state.searchedFullOfferValue,
  fullOfferFiltered: (state, getters) => {
    const parsedSearchedValue = find(getters.tournaments,
      (tournament) => (typeof tournament.shortcut === 'number'
        ? tournament.shortcut.toString().toLowerCase() : tournament.shortcut.toLowerCase())
        === getters.searchedFullOfferValue.toLowerCase())?.name || getters.searchedFullOfferValue;
    return filter(getters.events, (event) => {
      let isValid = true;
      if (parsedSearchedValue) {
        isValid = (event.tournament.name.toLowerCase()
          .indexOf(parsedSearchedValue.toLowerCase()) >= 0);
      }
      return isValid;
    });
  },
  tagsTournaments: (state, getters) => take(orderBy(filter(getters.tournaments,
    (tournament) => tournament.popular && tournament.active
      && tournament.categoryId in getters.categories
        && getters.categories[tournament.categoryId].active), 'popular'), 8),
  activeEdit: (state) => state.activeEdit,
  translations: (state) => state.translations,
  internetConnection: (state) => state.internetConnection,
  tournament: (state, getters) => (key) => getters.tournaments[key],
  futureBetsActive: (state) => state.futureBetsActive,
  futureBetsValue: (state) => state.futureBetsValue,
  getAvailableMarkets: (state, getters) => filter(getters.selectedEvent.event.markets, (market) => {
    let isValid = true;
    if (getters.searchedFullOfferValue) {
      isValid = (market.shortName.toLowerCase()
        .indexOf(getters.searchedFullOfferValue.toLowerCase()) >= 0);
    }
    return isValid;
  }),
};
