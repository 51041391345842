<template>
  <div class="modal-box"
       :style="{position: position}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ModalBox',
  props: {
    position: {
      type: String,
      default: 'fixed',
    },
  },
};
</script>

<style scoped>
  .modal-box {
    background-color: rgba(0, 0, 0, .6);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
  }
</style>
