const getBetName = (bet, playedNumbersLength, systemTranslation) => {
  let name = '';
  if (!bet.systemBetting) {
    name = bet.bet.betShortName;
  } else {
    const { systemTypes } = bet.systemBetting;
    const bankers = bet.systemBetting.bankers?.length || 0;
    const system = playedNumbersLength - bankers;
    const type = systemTypes.length === 1
      ? bet.systemBetting.systemTypes.toString() : `(${systemTypes.toString()})`;
    name = `${systemTranslation} ${type}/${system}`;
  }
  return name;
};

export default getBetName;
