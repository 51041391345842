function BetAtPayin(bet) {
  this.gameName = 'NumbersBetting';
  this.idBet = bet.id;
  this.gameType = bet.gameType;
  this.eventId = bet.round;
  this.outcomeOdd = bet.odd;
  this.outcomeType = bet.outcomeType;
  this.betSpecialValue = bet.specialValue;
  this.betStake = bet.payment;
  this.sameDrumBonusBalls = bet.betType === '833' || bet.betType === '1233';
  this.playedNumbers = bet.selectedNumbers.length && bet.betType !== '1232'
    ? bet.selectedNumbers.toString() : null;
  this.bonusBalls = bet.bonusBalls.length ? bet.bonusBalls.toString() : null;
  this.futureBets = bet.numEvents;
}

export default BetAtPayin;
