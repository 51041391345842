/* eslint-disable no-param-reassign */
import { forEach } from 'lodash';

const changeOddsOnBetslip = (bets, event) => {
  forEach(bets, (bet) => {
    if (bet.tournamentId === event.tournament.id) {
      bet.oldOdd = event.markets[bet.betType].outcomes[`${bet.betType}/${bet.outcomeType}`].oldOdd;
      bet.odd = event.markets[bet.betType].outcomes[`${bet.betType}/${bet.outcomeType}`].odd;
    }
  });
  return bets;
};

export default changeOddsOnBetslip;
