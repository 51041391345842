const taxLib = require('@nsftx/seven-gravity-tax-service');

/**
 * Calculate possible bet amount with or without tax
 * @param bet {object}
 * @param payinTax {string | null}
 * @returns {number}
 */
const calcPossibleBetAmount = (bet, payinTax = null) => {
  let tax;
  if (payinTax) {
    tax = taxLib.calculateTax(
      payinTax,
      bet.payment,
    );
  }
  const taxValue = tax ? tax.taxAmountRounded : 0;
  const stakeWithPayintax = +bet.payment - taxValue;
  return Number((bet.odd * stakeWithPayintax).toFixed(2));
};

export default calcPossibleBetAmount;
