import forEach from 'lodash/forEach';
import BetAtPayin from '@/model/BetAtPayin';

const prepareBetsForPayin = (bets) => {
  const newBets = [];
  forEach(bets, (bet) => {
    const prepBet = new BetAtPayin(bet);

    // Remove webCodeGenerated from the bets, as it is not needed
    // on the bets themselves, but on the ticket.
    // We eventually add this property to the ticket (see actions/ticketPayin)
    delete prepBet.webCodeGenerated;

    newBets.push(prepBet);
  });
  return newBets;
};

export default prepareBetsForPayin;
