import findIndex from 'lodash/findIndex';

/**
 * Check if we have category or tournament in events in some time range
 *
 * @param events {Array.<Object>}
 * @param data {object}
 * @param time {string}
 */
const checkCategoryTournamentInEvents = (events, data) => {
  const findIndexCategoryTournament = (type) => findIndex(events,
    (event) => data[type].id === event[type].id);
  const categoryIndex = findIndexCategoryTournament('category');
  const tournamentIndex = findIndexCategoryTournament('tournament');
  return {
    category: categoryIndex,
    tournament: tournamentIndex,
  };
};

export default checkCategoryTournamentInEvents;
