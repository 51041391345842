import {
  forEach,
  merge,
} from 'lodash';

const createHeaders = (data, newHeaders = null, removeHeader = null) => {
  let headers = {
    'Content-Type': 'application/json',
    'SEVEN-LOCALE': data.language,
    'HTTP-X-NAB-TOKEN': `${process.env.VUE_APP_XNAB}`,
    'HTTP-X-NAB-COMPANY-UUID': data.uuid,
    'HTTP-X-SEVEN-COMPANY-UUID': data.uuid,
  };
  if (removeHeader) {
    delete headers[removeHeader];
  }
  if (newHeaders) {
    headers = merge(headers, newHeaders);
  }
  return headers;
};

const createQueryParams = (data) => {
  let queryParams = '';
  forEach(data, (prop, key) => {
    queryParams += queryParams.length ? '&' : '?';
    queryParams += `${key}=${prop}`;
  });
  return queryParams;
};

export {
  createHeaders,
  createQueryParams,
};
