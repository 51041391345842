import { forEach } from 'lodash';
import { isBefore, subMinutes } from 'date-fns';
import { formatFns } from './dateFns';

const concatenateIterativly = (data, transformator) => {
  let concatenated = '';
  forEach(data, (obj, key) => {
    concatenated = concatenated
      ? `${concatenated}, ${key}:${transformator(obj)}`
      : `${key}: ${transformator(obj)}`;
  });

  return concatenated;
};

const getTimesPerMatch = (times) => {
  const timesTracker = {};

  times.forEach((time) => {
    const day = formatFns('YY-MM-DD', time);
    const timeOfDay = formatFns('HH:mm', time);
    const dayTimes = timesTracker[day];
    timesTracker[day] = dayTimes ? [...dayTimes, timeOfDay] : [timeOfDay];
  });

  return concatenateIterativly(timesTracker, (value) => Array.from(value).sort().join(', '));
};

const getSelectionTrackersAndNotificationData = (bets) => {
  const selectionTracker = {};
  const notificationData = {};

  bets.forEach((bet) => {
    const { selection, matchDateTimeUTC, matchName } = bet;
    // If the event has already started, add it to the array
    // of passed event times and ignore it
    // Stop betting will always come a minute before the event, hence
    // the -1 minute
    const stopBettingTime = subMinutes(new Date(matchDateTimeUTC), 1);
    if (isBefore(stopBettingTime, new Date())) {
      if (!notificationData[matchName]) {
        notificationData[matchName] = {
          droppedTimes: new Set([matchDateTimeUTC]),
        };
      } else {
        notificationData[matchName].droppedTimes.add(matchDateTimeUTC);
      }

      return;
    }

    // We're only keeping track of the first valid bet
    // and the number of remaining future bets.
    if (!selectionTracker[selection]) {
      selectionTracker[selection] = {
        first: bet,
        checked: 0,
      };
    } else {
      const selectionObject = selectionTracker[selection];
      const { first } = selectionObject;

      if (isBefore(matchDateTimeUTC, first.matchDateTimeUTC)) {
        selectionObject.first = bet;
      }

      selectionObject.checked += 1;
    }
  });

  return {
    selectionTracker,
    notificationData,
  };
};

const getUnavailableTimes = (notificationData) => concatenateIterativly(notificationData,
  ({ droppedTimes }) => getTimesPerMatch(droppedTimes));

export { concatenateIterativly, getSelectionTrackersAndNotificationData, getUnavailableTimes };
