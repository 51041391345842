import { find } from 'lodash';
import store from '../store';

const parseEdit = (edit) => {
  const { events } = store.getters;
  const editEvent = find(events, (item) => item.eventId === edit.round);
  return !editEvent ? null : {
    event: editEvent,
    bet: editEvent.markets[edit.betType],
    outcome: editEvent.markets[edit.betType].outcomes[`${edit.betType}/${edit.outcomeType}`],
    selectedNumbers: edit.selectedNumbers.slice(),
    bonusBalls: edit.bonusBalls.slice(),
    futureBets: edit.numEvents.toString(),
    webCodeGenerated: edit.webCodeGenerated,
  };
};

export default parseEdit;
