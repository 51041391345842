import Vue from 'vue';
import Config from '@/model/Config';
import EventBus from '@/store/EventBus';
import types from './mutationTypes';

export default {
  [types.SET_APP_IS_READY](state, payload) {
    state.appIsReady = payload;
    state.modalBoxComponent = null;
  },
  [types.SET_RAW_CONFIG](state, payload) {
    state.rawConfig = payload;
    state.config = new Config(payload);
    state.rules = payload.rules;
    state.betslip.stake = payload.rules.minBetAmount.value.toFixed(2);
  },
  [types.SET_BUS_SERVICE](state, payload) {
    state.busService = payload;
  },
  [types.SET_CATEGORIES](state, payload) {
    state.categories = { ...payload };
  },
  [types.SET_TOURNAMENTS](state, payload) {
    state.tournaments = { ...payload };
  },
  [types.SET_EVENTS](state, payload) {
    state.events = payload;
  },
  [types.SET_LOADER_STATUS](state, payload) {
    state.loaderStatus = payload;
  },
  [types.SET_ERROR_PAGE_MESSAGE](state, payload) {
    state.errorPageMessage = payload;
  },
  [types.SET_MODAL_BOX_COMPONENT](state, payload) {
    state.modalBoxComponent = payload;
  },
  [types.SET_TICKET_PREVIEW_DATA](state, payload) {
    state.ticketPreviewData = payload;
  },
  [types.SET_SELECTED_EVENT](state, payload) {
    if (payload) {
      Vue.set(state.selectedEvent, payload.name, payload.data);
    } else {
      Vue.set(state, 'selectedEvent', {
        event: null,
        bet: null,
        outcome: null,
      });
    }
  },
  [types.SET_SELECTED_EVENT_ERROR](state, payload) {
    state.selectEventError = payload;
  },
  /**
   * this to inputs will refactoring in future
   * UPDATE_SELECTED_NUMBERS and SET_BONUS_BALLS
   * can be one
   * @param state
   * @param payload
   */
  [types.UPDATE_SELECTED_NUMBERS](state, payload) {
    if (!payload) {
      Vue.set(state, 'selectedNumbers', []);
    } else if (payload.index !== null && payload.value !== null) {
      Vue.set(state.selectedNumbers, payload.index, payload.value);
    } else if (payload.index !== null && payload.value === null) {
      state.selectedNumbers.splice(payload.index, 1);
    } else {
      Vue.set(state, 'selectedNumbers', payload.value);
    }
  },
  [types.SET_BONUS_BALLS](state, payload) {
    if (!payload) {
      Vue.set(state, 'bonusBalls', []);
    } else if (payload.index !== null && payload.value !== null) {
      Vue.set(state.bonusBalls, payload.index, payload.value);
    } else if (payload.index !== null && payload.value === null) {
      state.bonusBalls.splice(payload.index, 1);
    } else {
      Vue.set(state, 'bonusBalls', payload.value);
    }
  },
  [types.SET_PRINT_TYPE](state, payload) {
    state.activePrintType = payload;
    state.activePrint = null;
  },
  [types.SET_RANDOM_ACTIVE](state, payload) {
    state.randomActive = payload;
  },
  [types.SET_APP_SHOWN](state, payload) {
    state.appShown = payload;
  },
  [types.SET_BETSLIP_BLOCKERS](state, payload) {
    state.betslipBlockers = payload;
  },
  [types.CHANGE_BETTING_STATUS_BET_ON_BETSLIP](state, payload) {
    Vue.set(state.betslip.tickets[state.betslip.ticketType.type][payload.index], 'locked', !payload.bettingStatus);
  },
  [types.SET_GLOBAL_STATUS](state, payload) {
    state.globalStatus = payload;
  },
  [types.UPDATE_BET_ON_EVENTS](state, payload) {
    Vue.set(state.events, payload.index, payload.event);
  },
  [types.REMOVE_EVENT_FROM_EVENTS](state, payload) {
    state.events.splice(payload, 1);
  },
  [types.ADD_CATEGORY_INTO_CATEGORIES](state, payload) {
    Vue.set(state.categories, payload.id, payload);
  },
  [types.ADD_TOURNAMENT_INTO_TOURNAMENTS](state, payload) {
    Vue.set(state.tournaments, payload.id, payload);
  },
  [types.UPDATE_EVENT_IN_EVENTS](state, payload) {
    Vue.set(state.events, payload.index, payload.data);
  },
  [types.REMOVE_CATEGORY_FROM_CATEGORIES](state, payload) {
    Vue.set(state.categories[payload], 'active', false);
  },
  [types.REMOVE_TOURNAMENT_FROM_TOURNAMENTS](state, payload) {
    Vue.set(state.tournaments[payload], 'active', false);
  },
  [types.UPDATE_BETS](state, payload) {
    Vue.set(state.betslip.tickets, state.betslip.ticketType.type, payload);
  },
  [types.SET_FULL_OFFER](state, payload) {
    state.fullOfferActive = payload;
  },
  [types.SEARCHED_FULL_OFFER_VALUE](state, payload) {
    state.searchedFullOfferValue = payload;
  },
  [types.UPDATE_SELECTED_EVENT](state, payload) {
    const { bet, webCodeGenerated } = payload;

    if (webCodeGenerated) {
      bet.webCodeGenerated = true;
    }

    Vue.set(state, 'selectedEvent', {
      event: payload.event,
      bet,
      outcome: payload.outcome,
    });
    Vue.set(state, 'selectedNumbers', payload.selectedNumbers);
    Vue.set(state, 'bonusBalls', payload.bonusBalls);
  },
  [types.SET_ACTIVE_EDIT](state, payload) {
    state.activeEdit = payload;
  },
  [types.UPDATE_BET](state, payload) {
    Vue.set(state.betslip.tickets[state.betslip.ticketType.type], payload.index, payload.data);
  },
  [types.SET_INTERNET_CONNECTION](state, payload) {
    state.internetConnection = payload;
  },
  [types.SET_SELECTED_EVENT_COPY](state, payload) {
    if (payload) {
      Vue.set(state, 'selectedEventCopy', { ...state.selectedEvent });
      Vue.set(state, 'selectedNumbersCopy', [...state.selectedNumbers]);
      Vue.set(state, 'bonusBallsCopy', [...state.bonusBalls]);
      state.internetConnection = false;
    } else {
      Vue.set(state, 'selectedEvent', { ...state.selectedEventCopy });
      Vue.set(state, 'selectedNumbers', state.selectedNumbersCopy);
      Vue.set(state, 'bonusBalls', state.bonusBallsCopy);
      Vue.set(state, 'selectedEventCopy', {
        event: null,
        bet: null,
        outcome: null,
      });
      Vue.set(state, 'selectedNumbersCopy', []);
      Vue.set(state, 'bonusBallsCopy', []);
      EventBus.$emit('updateInputs', {
        ...state.selectedEvent,
        selectedNumbers: state.selectedNumbers,
        bonusBalls: state.bonusBalls,
      });
    }
    setTimeout(() => {
      state.selectEventError = payload;
    }, 0);
  },
  [types.RESET_COPY](state) {
    Vue.set(state, 'selectedEventCopy', {
      event: null,
      bet: null,
      outcome: null,
    });
    Vue.set(state, 'selectedNumbersCopy', []);
    Vue.set(state, 'bonusBallsCopy', []);
  },
  [types.SET_FUTURE_BETS_ACTIVE](state, payload) {
    state.futureBetsActive = payload;
  },
  [types.SET_FUTURE_BETS_VALUE](state, payload) {
    state.futureBetsValue = payload;
  },
};
