import Vue from 'vue';
import Vuex from 'vuex';
import assign from 'lodash/assign';

import { SdkStore } from '@nsftx/games-sdk-js/';
import storeConfig from './storeConfig';

Vue.use(Vuex);
export default new Vuex.Store(assign(storeConfig, {
  modules: SdkStore.modules,
}));
