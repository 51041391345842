<template>
  <div id="app">
    <div class="app"
         v-if="appIsReady">
      <TemplateDefault></TemplateDefault>
    </div>
    <template v-if="modalBoxComponent">
      <ModalBox>
        <component :is="modalBoxComponent"></component>
      </ModalBox>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TemplateDefault from '@/layouts/TemplateDefault';
import ModalBox from '@/components/ModalBox';
import Loader from '@/components/Loader';
import TicketPreview from '@/components/TicketPreview';

import parseEdit from '@/utility/parseEdit';
import EventBus from '@/store/EventBus';
import ticketPreviewData from './mock-data/ticketPreviewData';

export default {
  name: 'App',
  data() {
    return {
      ticketPreviewData,
    };
  },
  components: {
    Loader,
    ModalBox,
    TicketPreview,
    TemplateDefault,
  },
  computed: {
    ...mapGetters([
      'appIsReady',
      'modalBoxComponent',
      'translate',
      'fullOfferActive',
      'config',
    ]),
  },
  methods: {
    ...mapActions({
      openTicketPreview: 'openTicketPreview',
      setLoaderStatus: 'setLoaderStatus',
      ticketPayin: 'ticketPayin',
      setFullOffer: 'setFullOffer',
      updateSelectedEvent: 'updateSelectedEvent',
      setActiveEdit: 'setActiveEdit',
      setFutureBetsActive: 'setFutureBetsActive',
      resetAll: 'resetAll',
    }),
  },
  created() {
    this.$root.$on('TicketPayin', () => {
      this.ticketPayin();
    });
    this.$root.$on('EditBet', (bet) => {
      if (this.fullOfferActive) {
        this.setFullOffer(false);
      }
      if (!bet) {
        this.setFutureBetsActive(false);
        this.setActiveEdit(null);
      } else {
        const edit = parseEdit(bet);
        if (edit) {
          EventBus.$emit('reset-inputs');
          this.resetAll({ skipEditOnBetslip: true });
          this.$nextTick(() => {
            this.setActiveEdit(bet);
            this.updateSelectedEvent({
              event: edit.event,
              bet: edit.bet,
              outcome: edit.outcome,
              selectedNumbers: edit.bet.betType === '1232' ? [] : edit.selectedNumbers,
              bonusBalls: edit.bonusBalls,
              futureBets: edit.futureBets,
              webCodeGenerated: edit.webCodeGenerated,
            });
            this.$nextTick(() => {
              if (bet.betType === '834') {
                document.querySelector('#bonusBall_0').select();
              } else {
                document.querySelector('#outcome_0').select();
              }
              if (edit.futureBets > 1) {
                EventBus.$emit('editFuture', edit.futureBets);
              }
            });
          });
        }
      }
    });
  },
  mounted() {
    document.documentElement.setAttribute('theme', 'dark');
  },
};
</script>

<style lang="scss">
@import './assets/icons';

@font-face {
  font-family: 'primary-font';
  src: url('~@/assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'primary-font-light';
  src: url('~@/assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'primary-font-bold';
  src: url('~@/assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'primary-font-condensed-bold';
  src: url('~@/assets/fonts/roboto/Roboto-Condensed-Bold.ttf')
    format('truetype');
}
@font-face {
  font-family: 'primary-font-medium';
  src: url('~@/assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
}

*,
*:before,
*:after {
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
body {
  color: #2E3743;
  position: relative;
  line-height: 1;
  font-size: 1rem;
  font-weight: normal;
  font-family: primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  width: 100%;
  font-size: 14px;
  overflow: auto;
}
html,
body,
#app,
.app {
  height: 100%;
}
button {
  cursor: pointer;
}
.betslip {
  input {
    font-weight: bolder;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}
.center-xy {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.save-row {
  display: none;
}
.dg.ac {
  z-index: 99999 !important;
}
</style>
