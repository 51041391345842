/* eslint-disable no-param-reassign,no-undef */

import {
  forEach,
  merge,
} from 'lodash';

/**
 * Parse data so we can work with events data
 * @param data {object} events, meta, mapper (mapper need for proper naming)
 * @param meta {object} categories and tournaments
 * @returns {*}
 */
const parseDataToBeRead = (data, meta) => {
  const parsedData = data;
  forEach(data.events, (event) => {
    forEach(event.markets, (market, key) => {
      merge(market, parsedData.meta.markets[key]);
      forEach(market.outcomes, (outcome, outcomeKey) => {
        merge(outcome, parsedData.meta.outcomes[outcomeKey]);
        forEach(outcome, (value, prop) => {
          outcome[parsedData.mapper.outcome[prop]] = value;
          delete outcome[prop];
        });
      });
      forEach(market, (marketPropValue, marketPropKey) => {
        if (Object.prototype.hasOwnProperty.call(parsedData.mapper.market, marketPropKey)) {
          market[parsedData.mapper.market[marketPropKey]] = marketPropValue;
          delete market[marketPropKey];
        }
      });
    });
    forEach(event, (eventPropValue, eventPropKey) => {
      if (Object.prototype.hasOwnProperty.call(parsedData.mapper.event, eventPropKey) && eventPropKey !== 'm') {
        event[parsedData.mapper.event[eventPropKey]] = eventPropValue;
        delete event[eventPropKey];
      }
    });
    if (Object.prototype.hasOwnProperty.call(meta.categories, event.category.id)) {
      merge(event.category, meta.categories[event.category.id]);
    }
    if (Object.prototype.hasOwnProperty.call(meta.tournaments, event.tournament.id)) {
      merge(event.tournament, meta.tournaments[event.tournament.id]);
    }
  });
  return parsedData;
};

export default parseDataToBeRead;
