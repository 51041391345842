import { uuid } from 'vue-uuid';
import { formatFns } from '@/utility/dateFns';

function Bet(data) {
  this.key = uuid.v4();
  this.eventName = data.event.tournament.name;
  this.tournamentId = data.event.tournament.id;
  this.gameType = data.event.tournament.gameType;
  this.market = data.bet.shortName;
  this.betType = data.bet.betType;
  this.specialValue = data.bet.specialValue;
  this.displayId = data.event.lotteryDisplayId || '-';
  this.outcome = data.bet.specialValue
    ? `${data.outcome.shortName} ${data.bet.specialValue}` : data.outcome.shortName;
  this.outcomeType = data.outcome.outcomeType;
  this.round = data.event.eventId;
  this.time = formatFns('DD.MM.YYYY. HH:mm', data.event.eventStartTimeUTC);
  this.timeTimestamp = data.event.eventStartTimeUTC;
  this.odd = data.outcome.odd;
  this.payment = data.payment;
  this.selectedNumbers = data.bet.betType !== '1232' ? data.selectedNumbers : '-';
  this.bonusBalls = data.bonusBalls;
  this.id = data.outcome.id;
  this.locked = false;
  this.oldOdd = null;
  this.numEvents = data.futureBets;
}

export default Bet;
