function Config(data) {
  this.applicationName = data.applicationName;
  this.currencyDisplay = data.currencyDisplay;
  this.environment = data.environment;
  this.locale = data.locale;
  this.timezone = data.tenantTimezone;
  this.platformName = data.platformName;
  this.productId = data.productId;
  this.productName = data.productName;
  this.rules = data.rules;
  this.tenantId = data.tenantId;
  this.translations = data.translations;
  this.productNameOrigin = data.productFqn;
  this.companyName = data.companyName;
  this.futureBets = data.futureBets;
}

export default Config;
