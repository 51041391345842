import {
  keys,
  forEach,
} from 'lodash';

/**
 * Update betting status for bet Stop
 * event - event object
 */
const updateBettingStatusForBetStop = (event, socketData) => {
  let betGroup;
  if (socketData.betGroup.length) {
    betGroup = socketData.betGroup;
  } else {
    betGroup = keys(event.markets);
    // eslint-disable-next-line no-param-reassign
    event.bettingStatus = socketData.bettingStatus;
  }
  forEach(betGroup, (betType) => {
    // eslint-disable-next-line no-param-reassign
    event.markets[betType].bettingStatus = socketData.bettingStatus;
  });
  return event;
};

export default updateBettingStatusForBetStop;
