import { find } from 'lodash';
import Bet from '../model/Bet';

const constructBetFromBetAndEventData = (bet, eventData) => new Bet({
  event: eventData,
  bet: eventData.markets[bet.bet.betDisplayId],
  outcome:
      eventData.markets[bet.bet.betDisplayId].outcomes[
        `${bet.bet.betDisplayId}/${bet.betOutcome.outcomeType}`
      ],
  selectedNumbers: bet.bet.playedNumbers ? bet.bet.playedNumbers.toString().split(',') : [],
  bonusBalls: bet.bet.bonusBalls ? bet.bet.bonusBalls.toString().split(',') : [],
  payment: bet.payment,
  futureBets: bet.futureBets,
});

const findEventByBet = (bet, events = []) => {
  const betEventId = (bet.matchId || bet.eventId).toString();
  return find(events, ({ eventId, bettingStatus }) => eventId === betEventId && bettingStatus);
};

export {
  constructBetFromBetAndEventData,
  findEventByBet,
};
