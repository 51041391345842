import Vue from 'vue';
import vuescroll from 'vuescroll';
import { initDatGui } from '@/utility/datGui';
import startListeners from '@/utility/startListeners';
import App from './App';
import router from './router';
import store from './store';
import i18n from './i18n';

import {
  busService,
  getConfigService,
  getMetaEvents,
} from './utility';

// let worker;

Vue.use(vuescroll, {
  ops: {
    bar: {
      background: 'rgb(0, 0, 0)',
      opacity: '.1',
      specifyBorderRadius: '3px',
    },
  },
});
(async () => {
  let config;
  new Vue({
    router,
    store,
    mounted() {
      // if (!worker) {
      //   worker = new Worker('/check_internet.js');
      //   worker.onmessage = (event) => {
      //     if (event.data === true && !store.getters.internetConnection) {
      //       store.dispatch('setInternetConnection', true);
      //       getMetaEvents(store, false);
      //     } else if (event.data === false && store.getters.internetConnection) {
      //       store.dispatch('setInternetConnection', false);
      //       store.commit('SET_SELECTED_EVENT_COPY', true);
      //     }
      //   };
      //   worker.postMessage({
      //     message: 'start',
      //   });
      // }
      window.addEventListener('offline', () => {
        store.dispatch('setInternetConnection', false);
        store.commit('SET_SELECTED_EVENT_COPY', true);
      });
      window.addEventListener('online', () => {
        store.dispatch('setInternetConnection', true);
        getMetaEvents(store, false);
      });
    },
    render: (h) => h(App),
    // beforeDestroy() {
    //   worker.terminate();
    // },
  }).$mount('#app');
  try {
    let platformConfig = null;
    window.addEventListener('Configuration:SetConfiguration', (platform) => {
      platformConfig = platform.detail;
    });
    startListeners();
    config = await getConfigService(store);
    config.betshopId = platformConfig.betshopId;
    config.deviceId = platformConfig.deviceId;
    config.channelId = platformConfig.channelId;
    if (config) {
      await store.dispatch('betslip/setPaymentPerBet', true);
      await store.dispatch('betslip/setFuturePerBet', config.futureBets);
      store.state.translations = (await i18n(config.locale)).default;
      if (process.env.NODE_ENV !== 'production') {
        initDatGui(store.state.translations, config.locale);
      }
      store.commit('SET_RAW_CONFIG', config);
      await store.dispatch('setRules', config.rules);
      await store.dispatch('setTranslations', config.translations);
      await busService(config, store);
      store.state.busService.addChannel('Device', config.deviceId);
      await getMetaEvents(store);
    }
  } catch (error) {
    console.log('error from main error');
  }
})();
