import {
  addDays,
  endOfDay,
  format,
} from 'date-fns';

const addDaysFns = (number) => addDays(new Date(), number);
const endOfDayFns = (date) => endOfDay(date);
const formatFns = (formatType = 'YYYY-MM-DD*HH:mm:ss', date = new Date()) => format(date, formatType);

export {
  addDaysFns,
  endOfDayFns,
  formatFns,
};
