import random from 'lodash/random';

/**
 * Get array of random numbers
 *
 * @param numb {number} how much numbers we need
 * @param max {number} range goes from 1 to max
 * @returns {[]}
 */
const getRandomNumbers = (numb, max) => {
  const numbers = [];
  let i = 0;
  while (i < numb) {
    const randomNum = random(1, max).toString();
    if (numbers.indexOf(randomNum) < 0) {
      numbers.push(randomNum);
      i += 1;
    }
  }
  return numbers;
};

export default getRandomNumbers;
