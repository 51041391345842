import { BusService } from '@nsftx/games-bus';

const busService = async (config, store) => {
  let gamesBusService;

  try {
    const adapters = [
      { name: 'PusherAdapter', options: { key: process.env.VUE_APP_PUSHER } },
    ];

    gamesBusService = await new BusService({
      environment: config.environment,
      platform: config.platform,
      productName: config.productName,
      productId: config.productId,
      tenantId: config.tenantId,
      locale: config.locale,
      applicationName: config.applicationName,
      useCommonEventName: true,
      adapters,
      clientMessageProtocol: config.messageProtocol,
    });
    gamesBusService.start();
    store.dispatch('setBusService', gamesBusService);
    store.dispatch('busServiceSendMessage', {
      action: 'Product.UpdateSettings',
      data: {
        icon: 'balls',
        shortcuts: {
          '+': {
            action: 'ticket',
            active: true,
            name: store.state.translations.shop_newTicket,
            shortcut: '+',
          },
          '*': {
            action: 'print',
            active: true,
            name: store.state.translations.shop_printOffer,
            shortcut: '*',
          },
          q: {
            action: 'results',
            active: true,
            name: store.state.translations.shop_resultsOffer,
            shortcut: 'q',
          },
          r: {
            action: 'random',
            active: true,
            name: store.state.translations.shop_random,
            shortcut: 'r',
          },
          o: {
            action: 'fullOffer',
            active: true,
            name: store.state.translations.shop_fullOffer,
            shortcut: 'o',
          },
          f: {
            action: 'futureBets',
            active: true,
            name: store.state.translations.general_future_bet,
            shortcut: 'f',
          },
          '/': {
            action: 'reset_ticket',
            active: true,
            name: store.state.translations.shop_resetTicket,
            shortcut: '/',
          },
        },
        ticketConfig: {
          color: '#1F9BDE',
          availableTicketActions: store.state.ticketActions,
          validateTicket: false,
          useTicketLocalStorage: true,
          doTicketStatusCheck: true,
          pendingTicketCheckInterval: 10000,
          pendingTicketRejectPeriod: 30000,
        },
      },
    });
  } catch (error) {
    store.dispatch('setErrorPageMessage', 'bus service error');
  }
  return gamesBusService;
};

export default busService;
