import {
  findIndex,
  forEach,
  find,
  indexOf,
  orderBy,
  isEqual,
  isEmpty,
  includes,
} from 'lodash';
import EventBus from '@/store/EventBus';
import router from '@/router';
import { getData } from '@/api';
import prepareBetsForPayin from '@/utility/prepareBetsForPayin';
import { validateBetsOnBetslip, checkForLockedBets, prepareNotificationForLockedBets } from '@/utility/validateBetsOnBetslip';
import updateBettingStatusForBetStop from '@/utility/updateBettingStatusForBetStop';
import checkCategoryTournamentInEvents from '@/utility/checkCategoryTournamentInEvents';
import {
  findEventByBet,
  constructBetFromBetAndEventData, getUnavailableTimes,
  getSelectionTrackersAndNotificationData,
} from '@/utility';
import updateOddsOnEvent from '@/utility/updateOddsOnEvent';
import changeOddsOnBetslip from '@/utility/changeOddsOnBetslip';
import Bet from '../model/Bet';
import types from './mutationTypes';

let updateSettings = {
  gamesHelp: true,
  calculator: true,
  ticketSession: true,
  ticketList: true,
};

export default {
  setStartApp: ({ commit }) => {
    commit(types.SET_APP_IS_READY, true);
    commit(types.SET_LOADER_STATUS, false);
  },
  handleBusMessage: async ({
    commit, dispatch, state, getters,
  }, payload) => {
    switch (payload.eventName) {
      // Pusher status
      case 'ticketUpdate':
      case 'TicketUpdate': {
        if (!payload.data.error) {
          const ticket = payload.data;
          ticket.translation = state.translations[ticket.translationKey || 'game_NumbersBetting'];
          const {
            payinTax,
            maxPossibleWin,
            payoutTax,
            maxPossiblePayout,
          } = ticket;
          ticket.payinTax = parseFloat(payinTax);
          ticket.maxPossibleWin = parseFloat(maxPossibleWin);
          ticket.payoutTax = parseFloat(payoutTax);
          ticket.maxPossiblePayout = parseFloat(maxPossiblePayout);
          ticket.config = {
            color: '#1F9BDE',
            availableTicketActions: state.ticketActions,
            validateTicket: false,
            useTicketLocalStorage: true,
            doTicketStatusCheck: true,
            pendingTicketCheckInterval: 10000,
            pendingTicketRejectPeriod: 30000,
          };
          dispatch('busServiceSendMessage', {
            action: 'Tickets.Update',
            data: {
              action: ticket.action,
              ticketData: ticket,
            },
          });
        }

        break;
      }
      case 'betStop':
        if (
          payload.data.eventId
          && payload.data.betGroup
          && 'bettingStatus' in payload.data
        ) {
          dispatch('checkBetsOnBetslip', payload);
          dispatch('handleBetStopSocket', payload.data);
        } else {
          console.warn('betStop warning/error', payload.data);
        }
        break;
      case 'eventStop':
        if (
          payload.data.eventId
          && 'bettingStatus' in payload.data
        ) {
          dispatch('checkBetsOnBetslip', payload);
          dispatch('handleEventStopSocket', payload.data);
        } else {
          console.warn('eventStop warning/error', payload.data);
        }
        break;
      case 'newEvent':
        dispatch('checkBetsOnBetslip', payload);
        dispatch('handleNewEventSocket', payload.data);
        break;
      case 'globalStatus':
        commit(types.SET_GLOBAL_STATUS, payload.data.bettingStatus);
        break;
      case 'updateCategoryTournament':
        dispatch('updateCategoryTournament', payload.data);
        break;
      case 'tenantOddsChanged': {
        let changeOdssActive = false;
        forEach(state.events, (event, index) => {
          if (event.tournament.id === payload.data.tournamentId) {
            const newEvent = updateOddsOnEvent(event, payload.data.markets);
            commit('UPDATE_EVENT_IN_EVENTS', {
              data: newEvent,
              index,
            });
            const bets = state.betslip.tickets[state.betslip.ticketType.type];
            if (!changeOdssActive && bets.length) {
              const newBets = changeOddsOnBetslip(bets, newEvent);
              commit('UPDATE_BETS', newBets, newEvent);
              changeOdssActive = true;
            }
          }
        });
        break;
      }
      // Gateway status
      case 'Widget.ClickedOutside':

        break;
      case 'Slave.Shown':
        commit(types.SET_APP_SHOWN, true);
        dispatch('closeTicketPreview');
        if (router.currentRoute.name !== 'home') {
          EventBus.$emit('goToPage', 'home');
        }
        dispatch('toggleShopSettings', !state.fullOfferActive);
        setTimeout(() => {
          EventBus.$emit('slaveShown', 'event');
        }, 10);
        break;
      case 'Master.Event':
        if (state.ticketPreviewData) {
          EventBus.$emit('ticketPreviewKeydown', payload);
        } else {
          dispatch('handleKeyboardEvents', {
            event: payload,
            code: payload.keyboardButton,
          });
        }
        break;
      case 'Widget.Event':
        if (payload.data.event === 'click' || payload.data.shortcut === '+') {
          dispatch('handleKeyboardEvents', {
            event: payload,
            code: payload.data.shortcut,
          });
        }
        if (payload.data.action?.acceptPayin) {
          dispatch('betslip/removeInvalidBets');
          dispatch('betslip/disablePayinButton', false);
          const inputPaymentPerBet = document.getElementsByName('inputPaymentPerBet');
          if (inputPaymentPerBet.length) {
            inputPaymentPerBet[0].focus();
          }
        } else if (payload.data.action?.key === '1') {
          dispatch('betslip/disablePayinButton', false);
        }
        break;
      case 'Betslip.Blocked':
        dispatch('betslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'add',
        });
        break;
      case 'Betslip.Unblocked':
        dispatch('betslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'remove',
        });
        break;
      case 'Ticket.CheckWebCode': {
        const url = `${process.env.VUE_APP_CHECK_WEB_CODE}/${payload.data.code}`;
        let request;

        try {
          const { data } = await getData(url, {
            'Content-Type': 'application/json',
            'HTTP-X-SEVEN-CLUB-UUID': getters.config.tenantId,
          });
          request = data.request;
          dispatch('addGeneratedTicket', request);
        } catch ({ response: { data } }) {
          if (data.action === 'checkWebCode' && data.code === 404) {
            dispatch('busServiceSendMessage', {
              action: 'Dialog.ShowModal',
              data: {
                action: '7S:Dialog.ShowModal',
                message: `${getters.translate('shop_webCodeInvalidOrExpired')}`,
                type: 'warning',
                delay: 3000,
              },
            });
          }
        }
      }
        break;
      case 'Tickets.ReBet':
        dispatch('betslip/clearBetslip', { root: true });
        dispatch('reBet', payload);
        break;
      case 'Tickets.Checked':
        dispatch('toggleShopSettings', false);
        dispatch('openTicketPreview', payload.data);
        break;
      case 'Tickets.PayingSentSuccess':
        dispatch('betslip/clearBetslip');
        dispatch('betslip/disablePayinButton', false);
        EventBus.$emit('selectEvent');
        break;
      case 'Tickets.PayingFailed':
        dispatch('betslip/disablePayinButton', false);
        break;
      case 'Tickets.PrePrint': {
        // const ticket = ticket in payload.data
        if (!payload.data.ticket.isCopy) {
          const type = {
            payout: 'ticketNumbersBettingPayout',
            cancel: 'ticketNumbersBettingCancel',
            add: 'ticketNumbersBetting',
          };
          dispatch('busServiceSendMessage', {
            action: 'Peripherals.Print',
            data: {
              additionalData: { winCap: state.config.rules.winCap.value },
              layoutName: type[payload.data.ticket.action.toLowerCase()],
              data: payload.data.ticket,
              productId: 'NumbersBetting',
            },
          });
        }
        break;
      }
      case 'Tickets.PrintCopy': {
        if (payload.data.ticket.isCopy) {
          const { ticket } = payload.data;
          ticket.translation = state.translations[ticket.translationKey || 'game_NumbersBetting'];
          const {
            payinTax,
            maxPossibleWin,
            payoutTax,
            maxPossiblePayout,
          } = ticket;
          ticket.payinTax = parseFloat(payinTax);
          ticket.maxPossibleWin = parseFloat(maxPossibleWin);
          ticket.payoutTax = parseFloat(payoutTax);
          ticket.maxPossiblePayout = parseFloat(maxPossiblePayout);
          dispatch('busServiceSendMessage', {
            action: 'Peripherals.Print',
            data: {
              additionalData: { winCap: state.config.rules.winCap.value },
              layoutName: ticket.status.value.toLowerCase() === 'payedout'
                ? 'ticketNumbersBettingPayout' : 'ticketNumbersBetting',
              data: ticket,
              productId: 'NumbersBetting',
            },
          });
        }
        break;
      }
      // no default
    }
  },
  /**
   * Handle key Events
   */
  handleKeyboardEvents: ({ commit, dispatch, state }, payload) => {
    switch (payload.code) {
      case 'r':
      case 'KeyR': {
        const bonusBallTypes = ['832', '833', '834', '1232', '1233'];
        if (includes(bonusBallTypes, state.selectedEvent.bet?.betType)) {
          commit(types.SET_RANDOM_ACTIVE, true);
        }
        break;
      }
      case 'f':
      case 'KeyF':
        if (state.config.futureBets) {
          EventBus.$emit('toggleFutureBets');
        }
        break;
      case 'NumpadAdd':
      case '+':
        EventBus.$emit('reset-inputs');
        dispatch('resetAll');
        break;
      case 'NumpadMultiply':
      case '*':
        EventBus.$emit('goToPage', 'print');
        break;
      case 'KeyQ':
      case 'q':
        EventBus.$emit('goToPage', 'print');
        setTimeout(() => {
          EventBus.$emit('activateResults');
        }, 100);
        break;
      case 'NumpadDivide':
      case 'Slash':
      case '/':
        dispatch('betslip/clearBetslip');
        break;
      case 'o':
      case 'KeyO':
        dispatch('setFullOffer', true);
        if (router.currentRoute.name === 'print') {
          EventBus.$emit('goToPage', 'home');
        }
        break;
      case 'm':
      case 'KeyM':
        if (payload.code === 'KeyM' && payload.event.ctrlKey) {
          const firstBetOnBetslip = document.getElementsByName('betslipBet0');
          if (firstBetOnBetslip.length) {
            firstBetOnBetslip[0].focus();
          }
        }
        break;
      // no default
    }
  },
  /**
   * Check bets on betslip
   * @param commit
   * @param getters
   * @param payload {object}
   */
  checkBetsOnBetslip: ({ commit, getters }, payload) => {
    forEach(getters['betslip/tickets'],
      (bet, index) => {
        let indexOfBet;
        switch (payload.type) {
          case 'eventStop':
          case 'newEvent':
            if (bet.round === payload.data.eventId) {
              commit(types.CHANGE_BETTING_STATUS_BET_ON_BETSLIP, {
                index,
                bettingStatus: payload.data.bettingStatus,
              });
            }
            break;
          case 'betStop':
            if (bet.round === payload.data.eventId) {
              indexOfBet = indexOf(payload.data.betGroup, bet.betType);
              if (indexOfBet >= 0 || !payload.data.betGroup.length) {
                commit(types.CHANGE_BETTING_STATUS_BET_ON_BETSLIP, {
                  index,
                  bettingStatus: payload.data.bettingStatus,
                });
              }
            }
            break;
          // no default
        }
      });
  },
  /**
   * Check betslip if there was internet down
   */
  checkBetslipAfterNoInternet: ({ commit, getters }) => {
    const tempDate = new Date().getTime();
    forEach(getters['betslip/tickets'], (bet, index) => {
      if ((bet.timeTimestamp - 60000) < tempDate) {
        commit(types.CHANGE_BETTING_STATUS_BET_ON_BETSLIP, {
          index,
          bettingStatus: false,
        });
      }
    });
  },
  /**
   * Socket messages
   *
   * Bet stop - used for activate/deactivate betting for some bets
   */
  handleBetStopSocket: ({ commit, state }, payload) => {
    const index = findIndex(state.events,
      (event) => event.eventId === payload.eventId);
    if (index >= 0) {
      const updatedEvent = updateBettingStatusForBetStop(state.events[index], payload);
      commit(types.UPDATE_BET_ON_EVENTS, { event: updatedEvent, index });
    }
  },
  /**
   * Handle evenst stop message
   */

  handleEventStopSocket: ({ commit, dispatch, state }, payload) => {
    const index = findIndex(state.events,
      (event) => event.eventId === payload.eventId);
    // Remove event from events
    if (index >= 0) {
      commit(types.REMOVE_EVENT_FROM_EVENTS, index);
    }
    if (payload.eventId === state.selectedEvent.event?.eventId) {
      EventBus.$emit('reset-inputs');
      dispatch('resetAll');
    }
  },
  /**
   * Handle new Events from socket
   * @param commit
   * @param dispatch
   * @param state
   * @param payload
   */
  handleNewEventSocket: ({ commit, dispatch, state }, payload) => {
    setTimeout(() => {
      if (state.events?.length) {
        const eventIndex = findIndex(state.events, (event) => event.eventId === payload.eventId);
        if (eventIndex >= 0) {
          commit(types.UPDATE_EVENT_IN_EVENTS, { index: eventIndex, data: payload });
        } else {
          const categoryTournamentIndex = checkCategoryTournamentInEvents(state.events, payload);
          if (categoryTournamentIndex.category < 0) {
            commit(types.ADD_CATEGORY_INTO_CATEGORIES, payload.category);
            commit(types.ADD_TOURNAMENT_INTO_TOURNAMENTS, payload.tournament);
          }
          if (categoryTournamentIndex.tournament < 0) {
            commit(types.ADD_TOURNAMENT_INTO_TOURNAMENTS, payload.tournament);
          }
        }
        const addEvents = state.events.slice();
        addEvents.push(payload);
        const orderEvents = orderBy(addEvents, 'eventStartTimeUTC');
        dispatch('setEvents', orderEvents);
      }
    }, 5000);
  },
  /**
   * Update category/tournament
   * @param commit
   * @param state
   * @param payload
   */
  updateCategoryTournament: ({ commit, state }, payload) => {
    const meta = payload.name === 'category' ? 'categories' : 'tournaments';
    const name = payload.name === 'category' ? 'Category' : 'Tournament';
    if (payload.data) {
      const update = name === 'Category' ? 'ADD_CATEGORY_INTO_CATEGORIES'
        : 'ADD_TOURNAMENT_INTO_TOURNAMENTS';
      if (Object.prototype.hasOwnProperty.call(state[meta], payload.id)) {
        commit(types[update], payload.data);
      }
    } else {
      const removeType = name === 'Category' ? 'REMOVE_CATEGORY_FROM_CATEGORIES'
        : 'REMOVE_TOURNAMENT_FROM_TOURNAMENTS';
      if (Object.prototype.hasOwnProperty.call(state[meta], payload.id)) {
        commit(types[removeType], payload.id);
      }
    }
  },
  /**
   * Send busService to be stored
   * @param payload {object}
   */
  setBusService: ({ commit }, payload) => {
    commit(types.SET_BUS_SERVICE, payload);
  },
  /**
   * Send categories to mutations
   * @param commit
   * @param payload {object}
   */
  setCategories: ({ commit }, payload) => {
    commit(types.SET_CATEGORIES, payload);
  },
  /**
   * Send tournaments to mutations
   * @param commit
   * @param payload {object}
   */
  setTournaments: ({ commit }, payload) => {
    commit(types.SET_TOURNAMENTS, payload);
  },
  /**
   * Send events to mutations
   * @param commit
   * @param payload {[]}
   */
  setEvents: ({ commit }, payload) => {
    if (payload.length) {
      commit(types.SET_EVENTS, payload);
    }
  },
  /**
   * Set loader status
   */
  setLoaderStatus: ({ commit }, payload) => {
    commit(types.SET_LOADER_STATUS, payload);
    commit(types.SET_MODAL_BOX_COMPONENT, payload ? 'Loader' : null);
  },
  setErrorPageMessage: ({ commit, dispatch }, payload) => {
    commit(types.SET_ERROR_PAGE_MESSAGE, payload);
    EventBus.$emit('goToPage', 'error');
    dispatch('setStartApp');
  },
  /**
   * Open ticket preview modal
   * @param commit
   * @param payload {}
   */
  openTicketPreview: ({ commit }, payload) => {
    commit(types.SET_TICKET_PREVIEW_DATA, payload);
    commit(types.SET_MODAL_BOX_COMPONENT, 'ticket-preview');
  },
  /**
   * Close ticket preview
   */
  closeTicketPreview: ({ commit, state }) => {
    commit(types.SET_TICKET_PREVIEW_DATA);
    if (state.appIsReady) {
      commit(types.SET_MODAL_BOX_COMPONENT);
    }
  },
  /**
   * Set selected props
   */
  setSelectedEvent: ({ commit, dispatch, state }, payload) => {
    commit(types.SET_SELECTED_EVENT, payload);
    if (!state.fullOfferActive) {
      setTimeout(() => {
        dispatch('toggleShopSettings', true);
      }, 50);
    }
  },
  updateSelectedEvent: ({ commit, dispatch }, payload) => {
    const data = payload?.data || payload;
    commit(types.UPDATE_SELECTED_EVENT, data);
    EventBus.$emit('updateInputs', data);
    if (!payload.data) {
      setTimeout(() => {
        dispatch('toggleShopSettings', true);
      }, 50);
    }
  },
  /**
   * Set selected event error
   */
  setSelectEventError: ({ commit }, payload) => {
    commit(types.SET_SELECTED_EVENT_ERROR, payload);
  },
  /**
   * Set selected event error
   */
  updateSelectedNumbers: ({ commit }, payload) => {
    commit(types.UPDATE_SELECTED_NUMBERS, payload);
  },
  setBonusBalls: ({ commit }, payload) => {
    commit(types.SET_BONUS_BALLS, payload);
  },
  setPrintType: ({ commit }, payload) => {
    commit(types.SET_PRINT_TYPE, payload);
  },
  setRandomActive: ({ commit }, payload) => {
    commit(types.SET_RANDOM_ACTIVE, payload);
  },
  setFullOffer: ({ commit }, payload) => {
    commit(types.SET_FULL_OFFER, payload);
  },
  setSearchedFullOfferValue: ({ commit }, payload) => {
    commit(types.SEARCHED_FULL_OFFER_VALUE, payload);
  },
  setActiveEdit: ({ commit }, payload) => {
    commit(types.SET_ACTIVE_EDIT, payload);
    if (!payload) {
      EventBus.$emit('reset-inputs');
    }
  },
  updateBet: ({ commit, state, dispatch }, payload) => {
    const index = findIndex(state.betslip.tickets[state.betslip.ticketType.type],
      (bet) => isEqual(bet, state.activeEdit));
    if (index < 0) {
      dispatch('betslip/addBet', payload);
    } else {
      commit(types.UPDATE_BET, {
        index,
        data: payload,
      });
    }
  },
  /**
   * send message to bus service
   */
  busServiceSendMessage: ({ state }, payload) => {
    if (!state.busService) {
      return;
    }
    const data = {
      action: payload.action,
    };
    if (payload.data) {
      data.data = payload.data;
    }
    state.busService.sendMessage(data, true);
  },
  /**
   * Used for adding a ticket after checking web code.
   */
  addGeneratedTicket: async ({ dispatch, state: { events }, getters }, payload) => {
    // First, reset the inputs
    EventBus.$emit('reset-inputs');
    await Promise.all([dispatch('betslip/clearBetslip', { root: true }), dispatch('resetAll')]);

    const { bets } = payload;
    let notificationMessage = '';

    // Run the bets through an algorithm which collects
    // events that have already begun, finds the first one that
    // hasn't begun yet, and calculates the number of the remaining future bets
    const { selectionTracker, notificationData } = getSelectionTrackersAndNotificationData(bets);

    // This means the algorithm has not found
    // any valid events to add to betslip
    if (isEmpty(selectionTracker)) {
      notificationMessage = `${getters.translate('shop_allEventsStarted')}`;
      dispatch('busServiceSendMessage', {
        action: 'Dialog.ShowModal',
        data: {
          action: '7S:Dialog.ShowModal',
          message: notificationMessage,
          type: 'warning',
          delay: false,
        },
      });
      return;
    }

    // Adds valid bets to the betslip
    forEach(selectionTracker, ({ checked, first }) => {
      const eventData = findEventByBet(first, events);
      if (eventData) {
        const betData = constructBetFromBetAndEventData(
          {
            ...first,
            futureBets: checked + 1,
          },
          eventData,
        );

        // Adding this prop so we can recognise bets added through a web code
        // because as long as at least one betslip bet has been generated through a web code
        // even if it's edited, the whole ticket is considered as web-code-generated
        betData.webCodeGenerated = true;

        dispatch('betslip/addBet', betData, { root: true });
        dispatch('setFutureBetsValue', checked + 1);
      }
    });

    // Groups unavailable events by matchName and day
    const allTimes = getUnavailableTimes(notificationData);

    // Display an appopriate message
    if (allTimes) {
      notificationMessage = `${getters.translate('shop_notfoundAlreadyStarted')}:${allTimes}`;
      dispatch('busServiceSendMessage', {
        action: 'Dialog.ShowModal',
        data: {
          action: '7S:Dialog.ShowModal',
          message: notificationMessage,
          type: 'warning',
          delay: false,
        },
      });
    }
  },
  reBet: ({ dispatch, state, getters }, payload) => {
    EventBus.$emit('reset-inputs');
    dispatch('resetAll');
    let isValid = true;
    forEach(payload.data.ticket.bets, (bet) => {
      if (isValid) {
        const eventData = find(state.events,
          (event) => event.eventId === (bet.matchId || bet.eventId).toString()
        && event.bettingStatus);
        if (eventData) {
          const betData = new Bet({
            event: eventData,
            bet: eventData.markets[bet.bet.betDisplayId],
            outcome: eventData.markets[bet.bet.betDisplayId].outcomes[`${bet.bet.betDisplayId}/${bet.betOutcome.outcomeType}`],
            selectedNumbers: bet.bet.playedNumbers
              ? bet.bet.playedNumbers.toString().split(',') : [],
            bonusBalls: bet.bet.bonusBalls ? bet.bet.bonusBalls.toString().split(',') : [],
            payment: bet.payment,
          });
          dispatch('betslip/addBet', betData, { root: true });
        } else {
          isValid = false;
        }
      }
    });
    if (!isValid) {
      dispatch('betslip/clearBetslip', { root: true });
      dispatch('busServiceSendMessage', {
        action: 'Dialog.ShowModal',
        data: {
          action: '7S:Dialog.ShowModal',
          message: `${getters.translate('shop_notfoundAlreadyStarted')}`,
          type: 'warning',
          delay: 3000,
        },
      });
    }
  },
  setBetslipBlockers: ({ commit, state }, payload) => {
    let i = 0;
    const newBetslipBlockers = state.betslipBlockers;
    const checkArray = () => findIndex(newBetslipBlockers,
      (blocker) => blocker.id === payload.blockers[i].id);
    while (i < payload.blockers.length) {
      const index = checkArray();
      if (payload.type === 'add') {
        if (index < 0) {
          newBetslipBlockers.push(payload.blockers[i]);
        }
      } else if (index >= 0 && payload.type !== 'add') {
        newBetslipBlockers.splice(index, 1);
      }
      i += 1;
    }
    commit(types.SET_BETSLIP_BLOCKERS, newBetslipBlockers);
  },
  ticketPayin: ({ dispatch, state, getters }) => {
    if (!state.internetConnection) {
      dispatch('busServiceSendMessage', {
        action: 'Dialog.ShowModal',
        data: {
          action: '7S:Dialog.ShowModal',
          message: `${getters.translate('general_noInternet')}`,
          type: 'warning',
          delay: 3000,
        },
      });
      dispatch('betslip/disablePayinButton', false);
      return;
    }
    const ticketBets = state.betslip.tickets[3];
    const webCodeGenerated = ticketBets.some((bet) => bet.webCodeGenerated);
    const sumOfLockedBets = checkForLockedBets(ticketBets);
    if (sumOfLockedBets) {
      const message = getters.translate('general_deleteLockedBets');
      const acceptMessage = getters.translate('general_acceptAndProceed');
      const dialogData = prepareNotificationForLockedBets(message, acceptMessage);
      dispatch('busServiceSendMessage', {
        action: 'Dialog.ShowModal',
        data: dialogData,
      });
    } else {
      const validateBets = validateBetsOnBetslip(ticketBets, state.config.rules);
      if (validateBets.isValid) {
        // must be changed, single tickets are type 3
        const bets = prepareBetsForPayin(ticketBets);
        const ticket = {
          bets,
          payin: state.betslip.totalPayment,
          product: state.config.productId,
          ticketComment: '',
          ticketCombinationGroups: [],
          ticketOddsOptions: 2,
          ticketType: 3,
        };
        const payinData = {
          validateTicket: false,
          skipBetslipReset: true,
          ticket,
          ticketFormatted: ticket,
        };

        if (webCodeGenerated) {
          payinData.ticket.webCodeGenerated = true;
        }

        dispatch('busServiceSendMessage', {
          action: 'Tickets.Pay',
          data: payinData,
        });
        dispatch('resetAll');
      } else {
        dispatch('busServiceSendMessage', {
          action: 'Dialog.ShowModal',
          data: {
            action: '7S:Dialog.ShowModal',
            message: `${getters.translate(validateBets.message)} ${validateBets.value}`,
            type: 'warning',
            delay: 3000,
          },
        });
      }
    }
  },
  resetAll: ({ dispatch }, payload) => {
    EventBus.$emit('reset-inputs');
    dispatch('setFutureBetsActive', false);
    dispatch('setSelectedEvent');
    dispatch('betslip/setEditBet', null);
    dispatch('setActiveEdit', null);
    dispatch('setRandomActive', null);
    if (!payload || !payload.skipSetFullOffer) {
      dispatch('setFullOffer', false);
    }
    setTimeout(() => {
      dispatch('setSelectEventError', null);
    }, 0);
  },
  setInternetConnection: ({ commit }, payload) => {
    commit(types.SET_INTERNET_CONNECTION, payload);
  },
  setSelectionEventCopy: ({ commit, state }) => {
    const tempDate = new Date().getTime();
    if (state.selectedEventCopy.event) {
      const index = findIndex(state.events,
        (event) => event.bettingStatus
          && (event.eventId === state.selectedEventCopy.event.eventId)
        && (event.eventStartTimeUTC - 60000) > tempDate) >= 0;
      if (index) {
        commit(types.SET_SELECTED_EVENT_COPY, false);
      } else {
        commit(types.RESET_COPY);
      }
    }
  },
  toggleShopSettings: ({ dispatch, state }, payload) => {
    let data = {};
    if (payload) {
      data = {
        gamesHelp: !state.selectedEvent.event,
        calculator: !state.selectedEvent.event,
        ticketSession: !state.selectedEvent.event,
        ticketList: true,
      };
    } else {
      data = {
        gamesHelp: false,
        calculator: false,
        ticketSession: false,
        ticketList: false,
      };
    }
    const isSame = isEqual(updateSettings, data);
    if (!isSame || (!payload && state.fullOfferActive)) {
      dispatch('busServiceSendMessage', {
        action: 'Widget.UpdateSettings',
        data: {
          gamesHelp: {
            show: data.gamesHelp,
          },
          calculator: {
            show: data.calculator,
          },
          ticketSession: {
            show: data.ticketSession,
          },
          ticketList: {
            show: data.ticketList,
          },
        },
      });
    }
    updateSettings = data;
  },
  setFutureBetsActive: ({ commit }, payload) => {
    commit(types.SET_FUTURE_BETS_ACTIVE, payload);
  },
  setFutureBetsValue: ({ commit }, payload) => {
    commit(types.SET_FUTURE_BETS_VALUE, payload);
  },
};
