import getRandomNumbers from '@/utility/getRandomNumbers';
import { validateBetsOnBetslip, checkForLockedBets } from '@/utility/validateBetsOnBetslip';
import { constructBetFromBetAndEventData, findEventByBet } from './betHelpers';
import busService from './busService';
import getConfigService from './getConfigService';
import getMetaEvents from './getMetaEvents';
import sortDataByProperty from './sortDataByProperty';
import getBetName from './getBetName';
import {
  concatenateIterativly,
  getSelectionTrackersAndNotificationData,
  getUnavailableTimes,
} from './checkWebCodeHelpers';
import { addDaysFns, endOfDayFns, formatFns } from './dateFns';

if (!String.prototype.supplant) {
  // eslint-disable-next-line
  String.prototype.supplant = function (o) {
    return this.replace(/{([^{}]*)}/g, (a, b) => {
      const r = o[b];
      return typeof r === 'string' || typeof r === 'number' ? r : a;
    });
  };
}

export {
  busService,
  getConfigService,
  getMetaEvents,
  addDaysFns,
  concatenateIterativly,
  getUnavailableTimes,
  getSelectionTrackersAndNotificationData,
  endOfDayFns,
  formatFns,
  constructBetFromBetAndEventData,
  findEventByBet,
  getRandomNumbers,
  validateBetsOnBetslip,
  checkForLockedBets,
  sortDataByProperty,
  getBetName,
};
