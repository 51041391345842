import http from './http';

/**
 * Create headers
 * @param lang
 * @param uuid
 * @returns {{"HTTP-X-SEVEN-COMPANY-UUID": string, "HTTP-X-NAB-COMPANY-UUID": string,
 * "HTTP-SEVEN-LOCALE", "Content-Type": string, "HTTP-X-NAB-TOKEN": string}}
 */
function getHeaders(lang, uuid) {
  return {
    'Content-Type': 'application/json',
    'HTTP-X-NAB-TOKEN': `${process.env.VUE_APP_XNAB}`,
    'HTTP-X-SEVEN-COMPANY-UUID': `${uuid}`,
    'HTTP-X-NAB-COMPANY-UUID': `${uuid}`,
    'HTTP-SEVEN-LOCALE': lang,
  };
}

/**
 * Fetch data from server
 *
 * @param url {string}
 * @param headers
 * @returns {AxiosPromise<any>}
 */
const getData = (url, headers) => http.get(url, { headers });

export {
  getData,
  getHeaders,
};
