import { ConfigurationService, registry } from '@nsftx/games-config';

const getConfigService = async (store) => {
  // eslint-disable-next-line func-names
  registry.httpAdapters.LotteryHttpAdapter.prototype.getEndpoint = function () {
    switch (this.options.environment) {
      case 'production':
        return 'https://games-lottery.de.nsoftcdn.com';
      default:
        return 'https://staging-nbg.nsoft.com:7443';
    }
  };
  let config;
  try {
    const configService = new ConfigurationService({
      requiredPaths: [
        'productId',
        'tenantId',
        'locale',
      ],
      messageProtocol: 'gravityGateway',
      clientAdapters: [
        {
          name: 'GravityGatewayAdapter',
          options: {
            isDebug: process.env.NODE_ENV === 'development',
            slaveId: 'NumbersBetting',
            gameId: 'NumbersBetting',
            data: {},
            eventListeners: {
              keydown: ['82', '106', '107', '111', '81', '77', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '96', '97', '98', '99', '100', '101', '102', '103', '104', '105'], // R, num*, num+, num/, Q, M
            },
            eventPropagation: {
              click: true,
              keydown: '*',
            },
          },
        },
      ],
      environment: process.env.VUE_APP_ENV === 'production' ? 'production' : 'staging',
      platformName: 'seven',
      productName: 'NumbersBetting',
      applicationName: 'Retail',
      checkIntervalTimeout: 15000,
    });
    config = await configService.getConfiguration();
    if (!config) {
      store.dispatch('setErrorPageMessage', 'Please try later no config data');
    }
  } catch (e) {
    console.log('config ERROR');
  }
  return config;
};

export default getConfigService;
