import axios from 'axios';
import isNil from 'lodash/isNil';
import {
  createQueryParams,
  createHeaders,
} from '@/api/utility';
import { getData } from '@/api';
import EventBus from '@/store/EventBus';
import parseDataToBeRead from './parseDataToBeRead';
import {
  addDaysFns,
  formatFns,
} from './dateFns';

let axiosSource = null;

function checkAxiosSource() {
  if (axiosSource) {
    axiosSource.cancel('Start new requeat, stop spam');
  }
  axiosSource = axios.CancelToken.source();
}
/**
 * Commit events after parsing to be read
 and commit meta and mapper from meta response
 * @param events {object}
 * @param meta {object}
 * @param store {object}
 * @returns {Promise<void>}
 */
const parsingData = async (events, meta, store) => {
  const parsedData = await parseDataToBeRead(events, meta);
  store.dispatch('setEvents', parsedData.events);
  store.dispatch('setStartApp');
};

/**
 * Fetch meta or events depending what we need
 * @param store
 * @returns {Promise<void>}
 */
const getMetaEvents = async (store, removeLoader = true) => {
  checkAxiosSource();
  store.dispatch('setLoaderStatus', true);
  try {
    const data = {
      tax: {
        payout: {
          hideTax: isNil(store.getters.rawConfig.rules.payoutTax.value),
          policy: store.getters.rawConfig.rules.payoutTax.value,
          value: !isNil(store.getters.rawConfig.rules.payoutTax.value),
        },
        payin: {
          hideTax: isNil(store.getters.rawConfig.rules.payinTax.value),
          policy: store.getters.rawConfig.rules.payinTax.value,
          value: !isNil(store.getters.rawConfig.rules.payinTax.value),
        },
      },
      peripherals: {
        printService: {
          oddsType: 'Decimal',
        },
      },
    };
    if (!store.getters.appIsReady) {
      store.dispatch('busServiceSendMessage', {
        action: 'Slave.Loaded',
        data,
      });
    }
    const language = store.state.config.locale;
    const querryData = {
      startDate: formatFns(),
      endDate: formatFns('YYYY-MM-DD*23:59:59', addDaysFns(6)),
      product: 'NumbersBetting',
      language,
      timezoneOffset: new Date().getTimezoneOffset(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      channel: store.state.config.applicationName.toLowerCase(),
    };
    const urlMeta = `${process.env.VUE_APP_META}NumbersBetting${createQueryParams(querryData)}`;
    const urlEvents = `${process.env.VUE_APP_EVENTS}NumbersBetting${createQueryParams(querryData)}`;
    const headersData = {
      language,
      uuid: store.state.config.tenantId,
      cancelToken: axiosSource.token,
    };
    const requestEvents = getData(
      urlEvents,
      createHeaders(headersData),
    );
    const requestMeta = getData(
      urlMeta,
      createHeaders(headersData),
    );
    axios.all([requestEvents, requestMeta]).then(axios.spread((...responses) => {
      const responseEvents = responses[0];
      const responseMeta = responses[1];
      store.dispatch('setCategories', responseMeta.data.categories);
      store.dispatch('setTournaments', responseMeta.data.tournaments);
      parsingData(responseEvents.data, responseMeta.data, store);
      if (!removeLoader) {
        store.dispatch('setSelectionEventCopy');
        store.dispatch('setLoaderStatus', false);
        store.dispatch('checkBetslipAfterNoInternet');
        EventBus.$emit('selectEvent');
      }
    })).catch(() => {
      store.dispatch('setErrorPageMessage', 'meta event request problem');
    });
  } catch (e) {
    store.dispatch('setErrorPageMessage', 'meta events state problem');
  }
};

export default getMetaEvents;
