<template>
    <div class="ticket-preview"
         v-if="ticketPreviewData">
      <div class="container">
        <button class="close-modal"
          @click="closePreview()">
          <em class="icon icon-close"></em>
        </button>
        <TicketPreviewHeader></TicketPreviewHeader>
        <div class="modal-body">
          <vue-scroll>
            <TicketPreviewDetails></TicketPreviewDetails>
          </vue-scroll>
        </div>
        <TicketPreviewFooter></TicketPreviewFooter>
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import TicketPreviewDetails from './TicketPreviewDetails';
import TicketPreviewHeader from './TicketPreviewHeader';
import TicketPreviewFooter from './TicketPreviewFooter';

export default {
  name: 'TicketPreview',
  components: {
    TicketPreviewDetails,
    TicketPreviewHeader,
    TicketPreviewFooter,
  },
  methods: {
    ...mapActions([
      'closeTicketPreview',
      'toggleShopSettings',
    ]),
    closePreview() {
      this.toggleShopSettings(true);

      this.closeTicketPreview();
    },
  },
  computed: {
    ...mapGetters(['ticketPreviewData']),
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.container {
  background-color: #fdfdfd;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  max-height: 500px;
  padding: 24px 19px;
  position: relative;
  width: 80%;
}

.close-modal {
  background: none;
  border: none;
  font-size: 44px;
  position: absolute;
  right: -44px;
  top: -44px;

  em.icon-close {
    color: #d4d4d4;
  }
}
.modal-header {
  color: #455a64;
  height: 81px;
  padding-bottom: 16px;

  .modal-title {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .modal-subtitle-item {
    display: inline-flex;
    font-size: 14px;

    b {
      margin-left: 4px;
    }

    &:last-child::after {
      content: none;
    }
  }

  .modal-subtitle-item::after {
    content: '|';
    margin: 0 5px;
  }
}

.modal-body {
  max-height: 200px;
  overflow-y: auto;
}
</style>
