import forEach from 'lodash/forEach';
import calcPossibleBetAmount from './calcPossibleBetAmount';

const isStakeValid = (stake, num, type) => {
  let isValid = null;
  let message = null;
  if (type === 'min') {
    isValid = stake >= num;
    message = isValid ? null : 'betslip_minBetAmount';
  } else {
    isValid = stake <= num;
    message = isValid ? null : 'betslip_maxBetAmount';
  }
  return {
    isValid,
    message,
    value: isValid ? null : num,
  };
};

const validateMaxWin = (bet, rules) => {
  const amount = calcPossibleBetAmount(bet, rules.payinTax.value);
  const isValid = rules.maxWinningCap.value >= amount;
  const message = isValid ? null : 'betslip_maxWinLimit';
  const value = isValid ? null : rules.maxWinningCap.value;
  return {
    isValid,
    message,
    value,
  };
};

const validateBetsOnBetslip = (bets, rules) => {
  let i = 0;
  let minStake;
  let maxStake;
  let maxWinnings;
  while (i < bets.length) {
    const betStake = bets[i].payment;
    minStake = isStakeValid(+betStake, rules.minBetAmount.value, 'min');
    maxStake = isStakeValid(+betStake, rules.maxBetAmount.value, 'max');
    maxWinnings = validateMaxWin(bets[i], rules);
    if (!minStake.isValid || !maxStake.isValid || !maxWinnings.isValid) {
      break;
    }
    i += 1;
  }
  return {
    isValid: minStake.isValid && maxStake.isValid && maxWinnings.isValid,
    message: minStake.message || maxStake.message || maxWinnings.message,
    value: minStake.value || maxStake.value || maxWinnings.value,
  };
};

const checkForLockedBets = (bets) => {
  let sumOfLockedBets = 0;
  forEach(bets, (bet) => {
    const value = bet.locked ? 1 : 0;
    sumOfLockedBets += value;
  });
  return sumOfLockedBets;
};
const prepareNotificationForLockedBets = (message, acceptMessage) => {
  const data = {
    action: '7S:Dialog.ShowModal',
    message,
    type: 'warning',
    showDefaultAction: {
      key: '1',
      keyCode: [49, 97],
    },
    actions: [
      {
        autoClose: true,
        acceptPayin: true,
        text: acceptMessage,
        key: '2',
        keyCode: [50, 98],
        noPermission: true,
      },
    ],
    focus: true,
  };
  return data;
};
export {
  validateBetsOnBetslip,
  checkForLockedBets,
  prepareNotificationForLockedBets,
};
