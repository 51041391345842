/* eslint-disable no-param-reassign */
import forEach from 'lodash/forEach';

const updateOddsOnEvent = (event, markets) => {
  forEach(event.markets, (market, key) => {
    forEach(market.outcomes, (outcome, outcomeKey) => {
      outcome.oldOdd = outcome.odd;
      outcome.odd = markets[key][outcomeKey].odd;
    });
  });
  return event;
};

export default updateOddsOnEvent;
