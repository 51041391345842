export default {
  action: 'Tickets.Checked',
  productId: 'SportradarVirtualFootball',
  data: {
    ticket: {
      statusDetails: '',
      code: '',
      message: '',
      translationKey: '',
      details: '',
      ticketCombinationGroups: [],
      rules: '',
      actionStatus: '',
      action: 'check',
      bet: 32,
      status: {
        id: 1, value: 'OPEN', name: 'OPEN', translated: 'OPEN',
      },
      product: 'NumbersBetting',
      type: 3,
      ticketDateTime: 1615465485000,
      ticketDateTimeUTC: 1615465485000,
      payinTax: 0,
      payin: 32,
      totalOdd: 19.600000000000001,
      maxPossibleWin: 156.80000000000001,
      maxPossiblePayoutTax: 0,
      possiblePayoutTax: 0,
      maxPossiblePayout: 156.80000000000001,
      winnings: 0,
      payoutTax: 0,
      payout: 0,
      idCompany: 91,
      deviceUuid: '',
      playerUuid: '25e37c6b-1dab-42d8-8eff-65e8bd495dd9',
      requestUuid: '23e6b58f-b3de-659c-da80-27f20471ccff',
      paymentId: 'f3e89fb3-24e6-45b6-ab2d-b93450d5ab6b',
      ticketPin: '8589',
      id: '7717M6RBQ',
      bets: [{
        specialValue: '',
        oddValue: 8,
        matchId: 2134564161,
        matchDisplayId: 1017,
        idTournament: null,
        matchName: 'USA-All or Nothing Morning Texas 12/24',
        matchDisplayName: 'USA-All or Nothing Morning Texas 12/24',
        matchDateTime: 1615478400000,
        matchDateTimeUTC: 1615478400000,
        ticketBetStatus: 'OPEN',
        matchResults: null,
        bonusResults: null,
        status: 5,
        ticketBetStatusTranslationKey: 'ticket_bet_open',
        idBet: 2134564161832,
        outcomeType: 'wns:hit:8:3',
        betStake: 8,
        possibleWin: 64,
        payout: null,
        payout_tax: null,
        betOutcome: {
          idBetOutcome: '2134564161832wns:hit:8:3',
          betOutcomeShortName: 3,
          betOutcomeDisplayName: 3,
          providerBetOutcomeId: '2134564161832wns:hit:8:3',
          outcomeType: 'wns:hit:8:3',
          outcomeShortcut: '3',
        },
        bet: {
          idBet: 275077,
          betDisplayName: 'Standard bet ',
          betShortName: 'Standard bet ',
          providerBetId: 2134564161832,
          betDisplayId: 832,
          playedNumbers: '22,12,13',
          bonusBalls: null,
        },
        teams: [],
      }, {
        specialValue: '',
        oddValue: 1.8,
        matchId: 2134564161,
        matchDisplayId: 1017,
        idTournament: null,
        matchName: 'USA-All or Nothing Morning Texas 12/24',
        matchDisplayName: 'USA-All or Nothing Morning Texas 12/24',
        matchDateTime: 1615478400000,
        matchDateTimeUTC: 1615478400000,
        ticketBetStatus: 'OPEN',
        matchResults: null,
        bonusResults: null,
        status: 5,
        ticketBetStatusTranslationKey: 'ticket_bet_open',
        idBet: 2134564161821,
        outcomeType: '70',
        betStake: 8,
        possibleWin: 14.4,
        payout: null,
        payout_tax: null,
        betOutcome: {
          idBetOutcome: 213456416182170,
          betOutcomeShortName: 'odd ',
          betOutcomeDisplayName: 'odd',
          providerBetOutcomeId: 213456416182170,
          outcomeType: '70',
          outcomeShortcut: '1',
        },
        bet: {
          idBet: 275080,
          betDisplayName: 'Draw sum odd/even ',
          betShortName: 'Draw sum odd/even ',
          providerBetId: 2134564161821,
          betDisplayId: 821,
          playedNumbers: '',
          bonusBalls: null,
        },
        teams: [],
      }, {
        specialValue: 'total=150.5;  ',
        oddValue: 1.8,
        matchId: 2134564161,
        matchDisplayId: 1017,
        idTournament: null,
        matchName: 'USA-All or Nothing Morning Texas 12/24',
        matchDisplayName: 'USA-All or Nothing Morning Texas 12/24',
        matchDateTime: 1615478400000,
        matchDateTimeUTC: 1615478400000,
        ticketBetStatus: 'OPEN',
        matchResults: null,
        bonusResults: null,
        status: 5,
        ticketBetStatusTranslationKey: 'ticket_bet_open',
        idBet: 2134564161822,
        outcomeType: '12',
        betStake: 8,
        possibleWin: 14.4,
        payout: null,
        payout_tax: null,
        betOutcome: {
          idBetOutcome: 213456416182212,
          betOutcomeShortName: 'over 150.5',
          betOutcomeDisplayName: 'over 150.5',
          providerBetOutcomeId: 213456416182212,
          outcomeType: '12',
          outcomeShortcut: '1',
        },
        bet: {
          idBet: 275083,
          betDisplayName: 'Draw sum total ',
          betShortName: 'Draw sum total ',
          providerBetId: 2134564161822,
          betDisplayId: 822,
          playedNumbers: '',
          bonusBalls: null,
        },
        teams: [],
      }, {
        specialValue: '',
        oddValue: 8,
        matchId: 2134564797,
        matchDisplayId: 1229,
        idTournament: null,
        matchName: 'USA-LuckyDay Lotto Midday 5/45',
        matchDisplayName: 'USA-LuckyDay Lotto Midday 5/45',
        matchDateTime: 1615488000000,
        matchDateTimeUTC: 1615488000000,
        ticketBetStatus: 'OPEN',
        matchResults: null,
        bonusResults: null,
        status: 5,
        ticketBetStatusTranslationKey: 'ticket_bet_open',
        idBet: 2134564797832,
        outcomeType: 'wns:hit:4:1',
        betStake: 8,
        possibleWin: 64,
        payout: null,
        payout_tax: null,
        betOutcome: {
          idBetOutcome: '2134564797832wns:hit:4:1',
          betOutcomeShortName: 1,
          betOutcomeDisplayName: 1,
          providerBetOutcomeId: '2134564797832wns:hit:4:1',
          outcomeType: 'wns:hit:4:1',
          outcomeShortcut: '1',
        },
        bet: {
          idBet: 275086,
          betDisplayName: 'Standard bet ',
          betShortName: 'Standard bet ',
          providerBetId: 2134564797832,
          betDisplayId: 832,
          playedNumbers: 44,
          bonusBalls: null,
        },
        teams: [],
      }],
    },
    actionData: {
      message: 'Ticket is won (1.19 BAM).',
      type: 'success',
      actions: [
        {
          autoClose: true,
          text: 'Pay out Ticket',
          key: 4,
          keyCode: [
            52,
            37,
            100,
          ],
          id: 'ticketPayout',
          callbackParams: null,
        },
        {
          autoClose: true,
          text: 'Print copy',
          key: 6,
          keyCode: [
            54,
            102,
          ],
          permission: 'ticketReprint',
          id: 'ticketCopy',
          callbackParams: null,
        },
      ],
      currentBalanceMessage: 'Current balance: 0.00 BAM',
    },
  },
  enforceEvent: false,
  msgSender: 'Master',
  plugin: 'GravityGateway',
};
