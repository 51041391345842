import store from '@/store';
import EventBus from '@/store/EventBus';
import router from '@/router';

const validateNumpad = (code) => {
  let valid = true;
  if (code.indexOf('Numpad') >= 0) {
    switch (code) {
      case 'NumpadAdd':
      case 'NumpadMultiply':
      case 'NumpadDivide':
        valid = true;
        break;
      default:
        valid = false;
        break;
    }
  }
  return valid;
};

const startListeners = () => {
  window.addEventListener('keydown', (event) => {
    const datgui = document.getElementsByClassName('dg main a has-save');
    if (!datgui.length || datgui[0].style.display === 'none') {
      if (store.state.ticketPreviewData) {
        EventBus.$emit('ticketPreviewKeydown', event);
      } else {
        if (event.code === 'KeyM' && event.ctrlKey) {
          const firstBetOnBetslip = document.getElementsByName('betslipBet0');
          if (firstBetOnBetslip.length) {
            firstBetOnBetslip[0].focus();
          }
          event.preventDefault();
          return;
        }
        if (event.code === 'Space') {
          if (event.target.id) {
            setTimeout(() => {
              document.getElementById(event.target.id).focus();
            }, 20);
          }
        }
        if ((event.code === 'ArrowUp' || event.code === 'ArrowDown'
            || event.code === 'Enter' || event.key === 'Enter')
          && store.state.fullOfferActive) {
          event.preventDefault();
          EventBus.$emit('arrowHandling', event.code);
        } else if (
          (event.target.id === 'bet_0' && event.code === 'KeyF')
          || (event.target.id.includes('outcome_') && event.code === 'KeyF')
          || (event.target.id !== 'fullOfferSearch'
          && (event.target.id !== 'bet_0'
            || ['NumpadAdd', 'NumpadDivide'].indexOf(event.code) >= 0)
          && (!(event.target.id.includes('outcome_') && event.code !== 'KeyR')
            || ['NumpadAdd', 'NumpadDivide'].indexOf(event.code) >= 0)
          && event.code.indexOf('Digit') < 0
          && validateNumpad(event.code)
          && ['Enter', 'Backspace', 'Tab', 'Period'].indexOf(event.code) < 0
          && !event.altKey
          && !event.metaKey
          && !event.ctrlKey
          && (event.code !== 'ArrowLeft' && event.code !== 'ArrowRight'))) {
          event.preventDefault();
          store.dispatch('handleKeyboardEvents', {
            event,
            code: event.code,
          });
        }
      }
    }
  });

  window.addEventListener('Bus:Message', (event) => {
    store.dispatch('handleBusMessage', event.detail);
  });
  EventBus.$on('goToPage', (name) => {
    if (router.currentRoute.name !== name) {
      store.dispatch('toggleShopSettings', name === 'home');
      router.push({ path: `/${name}` });
    }
  });
};

export default startListeners;
