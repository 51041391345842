export default {
  SET_APP_IS_READY: 'SET_APP_IS_READY',
  SET_RAW_CONFIG: 'SET_RAW_CONFIG',
  SET_BUS_SERVICE: 'SET_BUS_SERVICE',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_TOURNAMENTS: 'SET_TOURNAMENTS',
  SET_EVENTS: 'SET_EVENTS',
  SET_LOADER_STATUS: 'SET_LOADER_STATUS',
  SET_ERROR_PAGE_MESSAGE: 'SET_ERROR_PAGE_MESSAGE',
  SET_MODAL_BOX_COMPONENT: 'SET_MODAL_BOX_COMPONENT',
  SET_TICKET_PREVIEW_DATA: 'SET_TICKET_PREVIEW_DATA',
  SET_SELECTED_EVENT: 'SET_SELECTED_EVENT',
  SET_SELECTED_EVENT_ERROR: 'SET_SELECTED_EVENT_ERROR',
  UPDATE_SELECTED_NUMBERS: 'UPDATE_SELECTED_NUMBERS',
  SET_BONUS_BALLS: 'SET_BONUS_BALLS',
  SET_PRINT_TYPE: 'SET_PRINT_TYPE',
  SET_RANDOM_ACTIVE: 'SET_RANDOM_ACTIVE',
  SET_APP_SHOWN: 'SET_APP_SHOWN',
  SET_BETSLIP_BLOCKERS: 'SET_BETSLIP_BLOCKERS',
  CHANGE_BETTING_STATUS_BET_ON_BETSLIP: 'CHANGE_BETTING_STATUS_BET_ON_BETSLIP',
  SET_GLOBAL_STATUS: 'SET_GLOBAL_STATUS',
  UPDATE_BET_ON_EVENTS: 'UPDATE_BET_ON_EVENTS',
  REMOVE_EVENT_FROM_EVENTS: 'REMOVE_EVENT_FROM_EVENTS',
  ADD_CATEGORY_INTO_CATEGORIES: 'ADD_CATEGORY_INTO_CATEGORIES',
  ADD_TOURNAMENT_INTO_TOURNAMENTS: 'ADD_TOURNAMENT_INTO_TOURNAMENTS',
  UPDATE_EVENT_IN_EVENTS: 'UPDATE_EVENT_IN_EVENTS',
  REMOVE_CATEGORY_FROM_CATEGORIES: 'REMOVE_CATEGORY_FROM_CATEGORIES',
  REMOVE_TOURNAMENT_FROM_TOURNAMENTS: 'REMOVE_TOURNAMENT_FROM_TOURNAMENTS',
  UPDATE_BETS: 'UPDATE_BETS',
  SET_FULL_OFFER: 'SET_FULL_OFFER',
  SEARCHED_FULL_OFFER_VALUE: 'SEARCHED_FULL_OFFER_VALUE',
  UPDATE_SELECTED_EVENT: 'UPDATE_SELECTED_EVENT',
  SET_ACTIVE_EDIT: 'SET_ACTIVE_EDIT',
  UPDATE_BET: 'UPDATE_BET',
  SET_INTERNET_CONNECTION: 'SET_INTERNET_CONNECTION',
  SET_SELECTED_EVENT_COPY: 'SET_SELECTED_EVENT_COPY',
  RESET_COPY: 'RESET_COPY',
  SET_FUTURE_BETS_ACTIVE: 'SET_FUTURE_BETS_ACTIVE',
  SET_FUTURE_BETS_VALUE: 'SET_FUTURE_BETS_VALUE',
};
